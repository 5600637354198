<template>
    <modal ref="actualizarStock" titulo="Productos con stock insuficiente" tamano="modal-xl" icon="Filledoutline" no-aceptar>
        <div class="row justify-center">
            <p>Algunos productos se encuentran con stock insuficiente para el </br> pedido, te recomendamos actualizarlo para finalizar este pedido.</p>
        </div>
        <div class="row mx-0 my-3">
            <div v-for="(data, idx) in productos_stock_bajo" :key="idx" class="card-prod border-top p-2 m-2">
                <img width="90" height="90" :src="data.producto.imagen" alt="" />
                <div class="col f-14 my-auto">
                    <div class="row mx-0 mb-3">
                        <p class="ucfirst f-600">{{ data.producto.nombre }}</p>
                        <el-tooltip v-show="data.kit" content="Este producto hace parte del kit" placement="bottom" effect="light">
                            <i class="icon-star text-general-red f-18 position-absolute" style="right:0px" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 my-2 justify-content-between">
                        <p class="text-danger"><b>{{ fun_disponible(data) }} </b> {{ data.producto.unidad }}.</p>
                        <p><b>Pedido: {{ fun_cantidad(data) }} </b> {{ data.producto.unidad }}.</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            productos_stock_bajo: 'pedidos/pedidos_admin/productos_stock_bajo',
        }),
    },
    methods: {
        toggle(){
            this.$refs.actualizarStock.toggle()
        },
        fun_disponible(obj){
            if(obj.producto.cantidad_tipo === 1){
                return this.agregarSeparadoresNumero(obj.stock_disponible)
            }
            return this.agregarSeparadoresNumero(obj.stock_disponible,2)
        },
        fun_cantidad(obj){
            if(obj.producto.cantidad_tipo === 1){
                return this.agregarSeparadoresNumero(obj.cantidad)
            }
            return this.agregarSeparadoresNumero(obj.cantidad,2)
        }
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
